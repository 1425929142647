* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;

}

.App {
  width: auto;
  height: 57px;
  background-color: #101624; /*header color*/
}


.mobile {
  width: auto;
  height: 60px;
  background-color: #101624; /*header color*/
}

.App-logo {
  width: 160px;
  height: 32px;
  object-fit: contain;
  margin: 13px 289px 12px 90px;
}

.App-logo-mobile {
  margin-top: 10px;
  width: 50px;
  height: 40px;
  object-fit: contain;
}

