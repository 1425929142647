.sidebar {
  //assets
  width: 0px;
  height: 100%;
  position: absolute;
  top: 23px;
  margin-left: -17px;
  margin-top: 28px;
  
}

.material-button{
color: red;

}

.button {
  flex: 30%;
  height: 55px;
 padding-left: 10px;
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: #1e2539;
  border-bottom: solid 1px #27375c;
  color: #c9d0db;
  justify-content: center;
 
}

.assets {
  flex: 30%;
  height: 55px;
  padding-left: 35px;
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: #1e2539;
  border-bottom: solid 1px #27375c;
  color: #c9d0db;
 
}

.sidebarList .row {
  // width: 250px;
  height: 100%;
  padding-left: 30px;
  border-bottom: solid 1px #27375c;
  list-style-type: none;
  display: flex;
  color: #c9d0db;
  align-items: center;
  background-color: #1e2539;
  margin: 0%;
  flex-direction: row;
 
}

.row #icon {
  flex: 20%;
  display: grid;
  place-items: center;
  transform: scale(0.7);
}

.row #name {
  flex: 60%;
}

.sidebarList .row:hover {
  cursor: pointer;
  background-color: #1d2a43;
}

.sidebarList #active {
  background-color: #1d2a43;
}
