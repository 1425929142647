.itb-mobile {
  height: auto;
  background-color: #253046;
  // padding-left: 30px;
}

.night-mode-mobile {
  display: flex;
  width: auto;
}



.widget-container-mobile {
  margin-top: -20px;
  grid-column: 1;
}

.widget-container-mobile .itb-widget {

  min-width: unset;
  margin-bottom: 20px;
}

.widget-footer-mobile {
  // margin-top: 15px;
  display: block;
  justify-content: stretch;
  align-items: center;
}
