.night-mode {
 width: auto;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;
  padding: 20px;
  background-color: #253046
}

@media screen and (min-width: 1080px) {
  .night-mode {
    width: 900px;
    padding: 20px;
    height: auto;
  }
  }

//   .widget-container .itb-widget .signals-container .icICro {

//     width: fit-content;
//     // margin: 20px;
//     // margin-left: 50px;
//   }

//   .dKOwxe .itb-signals-global-summary {
//        width: 358px;
//     // padding-right: 200px;
//     // padding-left: 200px;
//     // margin-left: 50px;
//   }

//   .fgdTGj .summary-title {
//     text-align: left;
//  }

//  .fgdTGj .chart-container {
//   // margin-left: 230px;
//   width: 300px;
//   display: inline-block;
//   align-items: center;
//  }
//}

////@at-root

.dKOwxe .itb-signals-global-summary {
  width: 358px;
  // padding-right: 200px;
  // padding-left: 200px;
  // margin-left: 50px;
}

.widget-container {
  margin-top: -50px;
  max-width: 1440px;
  padding: 10px;
}

.widget-container .itb-widget {
  min-width: unset;
  display: inline-block;
}

.widget-container .itb-widget .signals-container .icICro {
  font-size: smaller;
  // background-color: aqua;
  // width: fit-content;
}

.widget-footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
