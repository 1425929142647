.drawer {
  background-color: #1e2539;
  margin: 0;
  padding: 0;
}

.button-mobile {
  width: 105%;
  flex: 30%;
  height: 55px;
 padding-left: 10px;
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: #1e2539;
  border-bottom: solid 1px #27375c;
  color: #c9d0db;
  justify-content: center;
 
}

.assets-mobile {
  flex: 30%;
  width: 120%;
  height: 50px;
  padding-left: 30px;
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: #1e2539;
  border-bottom: solid 1px #27375c;
  color: #c9d0db;
  
}

.SidebarList-mobile {
 
  height: auto;
  width: 250px;
  padding: 0;
  margin: 0;
}

.SidebarList-mobile .row-mobile {
  width: 120%;
  height: 50px;
  border-bottom: solid 1px #27375c;
  list-style-type: none;
  display: flex;
  color: #c9d0db;
  align-items: center;
  background-color: #1e2539;
  padding: 10px 10px 10px 10px;
  flex-direction: row;
}

.row-mobile #icon-mobile {
  flex: 30%;
  display: grid;
  place-items:  center;
  transform: scale(0.70);
  padding-bottom: 10px;
  padding-top: 10px;
}

.row-mobile #name-mobile {
  flex: 70%;
}

.SidebarList-mobile .row-mobile:hover {
  cursor: pointer;
  background-color: #1d2a43;
}

.SidebarList-mobile #active-mobile {
  background-color: #1d2a43;
}

.header-logo {
  width: 160px;
  height: 32px;
  object-fit: contain;
  display: flex;
}